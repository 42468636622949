import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { studentService } from '../services/studentService'

export const getAllStudents = createAsyncThunk('student/getAll', async (_, { rejectWithValue }) => {
  try {
    const response = await studentService.getAll()
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getOneStudent = createAsyncThunk('student/getOne', async (data, { rejectWithValue }) => {
  try {
    const response = await studentService.getOne(data)
    return response.data
  } catch (error) {
    return rejectWithValue(error?.response?.data)
  }
})

export const generateAccountSlice = createAsyncThunk(
	"generate/account",
	async (data, { rejectWithValue }) => {
		try {
			const response = await studentService.generateAccountService(data);
			console.log("🚀 ~ response:generateAccountSlice", response);

			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);
export const bulkSubscriptionSlice = createAsyncThunk(
	"bulk/subscription",
	async (data, { rejectWithValue }) => {
		try {
			const response = await studentService.bulkSubscriptionService(data);
			console.log("🚀 ~ response:bulkSubscriptionSlice", response);

			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

const initialState = {
	data: [],
	singleData: {},
	loading: false,
	error: false,
	message: "",
};

const slice = createSlice({
	name: "students",
	initialState,
	reducers: {
		checkAll: (state) => {
			state.isChecked = !state.isChecked;
		},
		resetSingleData: (state) => {
			state.singleData = {};
		},
	},
	extraReducers: {
		[getAllStudents.pending]: (state) => {
			if (state.data.length <= 0) {
				state.loading = true;
			}
		},
		[getAllStudents.fulfilled]: (state, action) => {
			state.error = false;
			state.data = action.payload;
			state.loading = false;
		},
		[getAllStudents.rejected]: (state, action) => {
			state.error = true;
			state.message = action.payload;
			state.loading = false;
		},

		[getOneStudent.pending]: (state) => {
			state.loading = true;
		},
		[getOneStudent.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
			state.singleData = payload;
		},
		[getOneStudent.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},

		// generate account
		[generateAccountSlice.pending]: (state) => {
			state.loading = true;
		},
		[generateAccountSlice.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
			state.singleData = payload;
		},
		[generateAccountSlice.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},

		// bulk subscription
		[bulkSubscriptionSlice.pending]: (state) => {
			state.loading = true;
		},
		[bulkSubscriptionSlice.fulfilled]: (state, { payload }) => {
			state.message = payload?.message;
			state.loading = false;
			state.singleData = payload;
		},
		[bulkSubscriptionSlice.rejected]: (state, { payload }) => {
			state.error = true;
			state.message = payload;
			state.loading = false;
		},
	},
});



export const { resetSingleData } = slice.actions
export default slice.reducer
