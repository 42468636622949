import AuthAPI from './authInstance'

const getAll = async () => {
  const response = await AuthAPI.get(`/admin/student/list`)
  return response.data
}

const getOne = async (data) => {
  const response = await AuthAPI.get(`/admin/student/show/${data}`)
  return response.data
}

const generateAccountService = async (data) => {
	const response = await AuthAPI.post(
		`/admin/account-generation/generate-users`,
		data
	);
	return response;
};
const bulkSubscriptionService = async (data) => {
	const response = await AuthAPI.post(
		`/admin/account-generation/bulk-subscribe-to-plan`,
		data
	);
	return response;
};

export const studentService = {
	getAll,
	getOne,
	generateAccountService,
	bulkSubscriptionService,
};
